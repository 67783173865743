import {
  ModalTabs,
  ModalTab,
  ModalItem
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import './fgts-modal-offer.scss'

const FGTSModalContent = () => {
  const {
    offerModalContent: { proposal }
  } = useLead()

  const {
    installmentsNumber,
    cetYearly,
    cetMonthly,
    interestRateMonthly,
    interestRateYearly,
    iofValue,
    maxLoanValue,
    requestedLoanValue,
    additionalData
  } = proposal

  const { installmentsTotal, installments } = additionalData

  return (
    <aside className="fgts-modal-offer">
      <ModalTabs>
        <ModalTab title="Geral">
          <div className="fgts-modal-offer__wrapper">
            <ModalItem
              item="Você vai receber"
              value={formatters.number.toCurrency(requestedLoanValue)}
            />

            <ModalItem
              item="Saques antecipados"
              value={`${installmentsNumber} saque${
                installmentsNumber > 1 ? 's' : ''
              }`}
            />

            <ModalItem
              item="Valor máximo liberado"
              value={formatters.number.toCurrency(maxLoanValue)}
            />

            <ModalItem
              item="Taxa de juros"
              value={`${formatters.number.toPercent(
                interestRateMonthly,
                2
              )} ao mês
              <br /> ${formatters.number.toPercent(interestRateYearly, 2)} ao
              ano`}
            />

            <ModalItem
              item="IOF máximo (Imposto sobre operação financeira)"
              value={formatters.number.toCurrency(iofValue)}
            />

            <ModalItem
              divider={false}
              item="CET <br /> (custo efetivo total)"
              value={`${formatters.number.toPercent(
                cetMonthly,
                2
              )} ao mês <br />
              ${formatters.number.toPercent(cetYearly, 2)} ao ano`}
            />

            <p className="fgts-modal-offer__disclaimer">
              *Proposta sujeita à análise de crédito
            </p>
          </div>
        </ModalTab>
        <ModalTab title="Antecipações">
          <div className="fgts-modal-offer__wrapper">
            {Object.keys(installments).map((key) => {
              return (
                <div key={key} className="fgts-modal-offer__table-item">
                  <ModalItem
                    item={key}
                    value={formatters.number.toCurrency(installments[key])}
                  />
                </div>
              )
            })}
            <ModalItem
              divider={false}
              item="Valor total bloqueado no seu FGTS (todas as parcelas com os
                encargos)"
              value={formatters.number.toCurrency(installmentsTotal)}
            />
          </div>
        </ModalTab>
      </ModalTabs>
    </aside>
  )
}

export default FGTSModalContent
