import { SecondOffer } from '@itau-loans-www/shopping/src/components'

const CardAlone = ({ proposals = [] }) => {
  const proposal = proposals[0]

  return (
    <div className="card-alone">
      <SecondOffer className="-alone" proposal={proposal} index={0} />
    </div>
  )
}

export default CardAlone
