import { useEffect } from 'react'

import { IdsModal, IdsMainButton } from '@ids/react'
import {
  If,
  OfferAllProducts,
  LockedProposals
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import Layout from '@itau-loans-www/shopping/src/layouts/Offer'
import analytics from '@mobi/libraries/analytics'

import { isClient } from 'utils/index'

import Banner from './components/Banner'
import ConsigNCorModalContent from './components/ConsigNcorModalContent'
import FGTSModalContent from './components/FGTSModalContent'
import OtherProposals from './components/OtherProposals'
import RefinModalContent from './components/RefinModalContent'
import './offers.scss'

const Offers = () => {
  const {
    offerModalOpen,
    setOfferModalOpen,
    offerModalContent,
    leadData: { name },
    offers: { hero, others, blocked, errorTitle }
  } = useLead()

  const offerlessChannel = !!hero?.offerlessChannel
  const hasHero =
    typeof hero?.productCode === 'string' && offerlessChannel !== true

  const fgtsLabel =
    offerModalContent?.productCode === 'fgts_app'
      ? 'Finalizar no app Itaú'
      : 'Seguir para contratação'

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'vitrine-shopping' })
  }, [])

  return (
    <Layout>
      <Banner
        offerlessChannel={offerlessChannel}
        leadName={name}
        errorTitle={errorTitle}
        hasHero={hasHero}
        proposal={hero}
      />
      <div className="container">
        <div id="body-offers">
          <If
            condition={others}
            renderIf={
              <OtherProposals
                offerlessChannel={offerlessChannel}
                proposals={others}
              />
            }
          />
          <If
            condition={blocked}
            renderIf={<LockedProposals proposals={blocked} />}
          />
        </div>
        <OfferAllProducts />
        <If
          condition={isClient()}
          renderIf={
            <>
              <IdsModal
                title="detalhes da oferta"
                width="600px"
                noDivider
                open={
                  offerModalOpen &&
                  offerModalContent?.productCode === 'consig_refin_inss_ncor'
                }
                footer={
                  <>
                    <IdsMainButton
                      full
                      onClick={() => offerModalContent?.callback()}
                    >
                      Seguir para contratação
                    </IdsMainButton>
                  </>
                }
                setOpen={() => setOfferModalOpen(false)}
              >
                <RefinModalContent />
              </IdsModal>
              <IdsModal
                title="detalhes da oferta"
                width="600px"
                noDivider
                open={
                  offerModalOpen &&
                  offerModalContent?.productCode === 'consig_ncor'
                }
                setOpen={() => setOfferModalOpen(false)}
                footer={
                  <>
                    <IdsMainButton
                      full
                      onClick={() => offerModalContent?.callback()}
                    >
                      Seguir para contratação
                    </IdsMainButton>
                  </>
                }
              >
                <ConsigNCorModalContent />
              </IdsModal>
              <IdsModal
                title="detalhes da oferta"
                noDivider
                open={
                  offerModalOpen &&
                  ['FGTS', 'fgts_app'].includes(offerModalContent?.productCode)
                }
                setOpen={() => setOfferModalOpen(false)}
                width="600px"
                footer={
                  <>
                    <IdsMainButton
                      full
                      onClick={() => offerModalContent?.callback()}
                    >
                      {fgtsLabel}
                    </IdsMainButton>
                  </>
                }
              >
                <FGTSModalContent />
              </IdsModal>
            </>
          }
        />
      </div>
    </Layout>
  )
}

export default Offers
