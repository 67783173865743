import {
  ModalTabs,
  ModalTab,
  ModalItem
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import './refin-modal-offer.scss'

const RefinModalContent = () => {
  const {
    number: { toPercent, toCurrency }
  } = formatters

  const { offerModalContent } = useLead()

  const { additionalData, maxLoanValue } = offerModalContent

  const {
    creditOperationNumberOrigin,
    customerAgreementNumber,
    newInstallmentsQty,
    newInstallmentsValue,
    newInterestRateMonthly,
    newInterestRateYearly,
    newRefinTotalAmount,
    newRefinTransshipmentAmount,
    oldInstallmentsValue,
    oldInterestRateMonthly,
    oldInterestRateYearly,
    oldPaidInstallmentsQty,
    newCETMonthly,
    newCETYearly,
    newIOFValue
  } = additionalData

  return (
    <div className="refin-modal-offer">
      <div className="refin-modal-offer__tabs">
        <ModalTabs>
          <ModalTab title="Contrato atual">
            <div className="refin-modal-offer__wrapper">
              <ModalItem
                item="Contrato atual a ser refinanciado"
                value={creditOperationNumberOrigin}
              />

              <ModalItem
                item="Benefício INSS"
                value={customerAgreementNumber}
              />

              <ModalItem
                item="Valor da Parcela"
                value={toCurrency(oldInstallmentsValue)}
              />

              <ModalItem
                item="Parcelas pagas"
                value={`${oldPaidInstallmentsQty} de ${80}`}
              />

              <ModalItem
                item={`Taxas de juros atual`}
                value={`
                  ${toPercent(oldInterestRateMonthly, 2)} ao mês
                  <br/>
                  ${toPercent(oldInterestRateYearly, 2)} ao ano
                `}
                divider={false}
              />
            </div>
          </ModalTab>
          <ModalTab title="Novo contrato">
            <div className="refin-modal-offer__wrapper --secondary">
              <ModalItem
                item="Condição de pagamento"
                value={`${newInstallmentsQty}x de ${toCurrency(
                  newInstallmentsValue
                )}`}
              />

              <ModalItem
                item="Valor adicional na sua conta"
                value={toCurrency(newRefinTransshipmentAmount)}
              />

              <ModalItem
                item="Valor liberado"
                value={toCurrency(maxLoanValue)}
              />

              <ModalItem
                item={'Nova taxa de juros'}
                value={`
                  ${toPercent(newInterestRateMonthly, 2)} ao mês
                  <br/>
                  ${toPercent(newInterestRateYearly, 2)} ao ano
                `}
              />
              <ModalItem
                item={`
                    Novo valor IOF (imposto sobre operação financeira)
                  `}
                value={toCurrency(newIOFValue)}
              />

              <ModalItem
                item={`Novo CET <br/> (custo efetivo total)`}
                value={`
                  ${toPercent(newCETMonthly, 2)} ao mês
                  <br/>
                  ${toPercent(newCETYearly, 2)} ao ano
                `}
              />
              <ModalItem
                item={'Valor total a ser pago'}
                value={toCurrency(newRefinTotalAmount)}
                divider={false}
              />

              <p className="refin-modal-offer__disclaimer">
                *Proposta sujeita à análise de crédito
              </p>
            </div>
          </ModalTab>
        </ModalTabs>
      </div>
    </div>
  )
}

export default RefinModalContent
