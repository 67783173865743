import { If } from '@itau-loans-www/shopping/src/components'
import classnames from 'classnames'

import CardAlone from './components/CardAlone'
import CardCarousel from './components/CardCarousel'
import './other-proposals.scss'

const OtherProposals = ({ proposals, offerlessChannel }) => {
  const isAbleToCarousel = proposals?.length > 1
  const classes = classnames('other-proposals', {
    'other-proposals--blocked': offerlessChannel
  })

  return (
    <section className={classes}>
      <div className="other-proposals__content">
        <div className="other-proposals__col">
          <p className="other-proposals__title">
            Outras propostas que estão disponíveis para você
          </p>
        </div>
        <If
          condition={isAbleToCarousel}
          renderIf={<CardCarousel proposals={proposals} />}
          renderElse={<CardAlone proposals={proposals} />}
        />
      </div>
    </section>
  )
}

export default OtherProposals
