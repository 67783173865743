import { SecondOffer } from '@itau-loans-www/shopping/src/components'
import { useMediaQuery } from '@itau-loans-www/shopping/src/hooks'
import { Carousel, CarouselItem } from '@mobi/ds'

import { CAROUSEL_CONFIG } from './constants'

const CardCarousel = ({ proposals }) => {
  const isMobile = useMediaQuery('mobileLandscape')

  const isLongProposalList = proposals?.length > 3

  const showPasser = isMobile || isLongProposalList

  return (
    <div className="card-carousel">
      <Carousel
        indentifier="other-proposals"
        variant="slider"
        bullets={showPasser}
        arrows={showPasser}
        transition={showPasser}
        options={CAROUSEL_CONFIG}
        className="other-proposals__carousel"
      >
        {proposals.map((proposal, idx) => (
          <CarouselItem key={`${idx}-carousel`}>
            <SecondOffer
              className="--carousel"
              proposal={proposal}
              index={idx}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  )
}

export default CardCarousel
