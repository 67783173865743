export const CAROUSEL_CONFIG = {
  perView: 3,
  focusAt: 0,
  bound: true,
  gap: 40,
  breakpoints: {
    1440: {
      perView: 2.9
    },
    780: {
      perView: 2.25
    },
    576: {
      perView: 1.25
    },
    420: {
      perView: 1.1
    }
  }
}
