import { ModalItem } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import { formatters } from 'utils'

import './consigNcor-modal-offer.scss'

const ConsigNCorModalContent = () => {
  const {
    offerModalContent: {
      requestedLoanValue,
      maxLoanValue,
      installmentsValue,
      installmentsNumber,
      interestRateMonthly,
      interestRateYearly,
      cetMonthly,
      cetYearly,
      iofValue,
      loanTotal
    }
  } = useLead()

  return (
    <div className="consigNcor-modal-offer">
      <ModalItem
        item="Valor do empréstimo"
        value={formatters.number.toCurrency(requestedLoanValue)}
      />

      <ModalItem
        item="Condição de pagamento"
        value={`${installmentsNumber}x de ${formatters.number.toCurrency(
          installmentsValue
        )}`}
      />

      <ModalItem
        item="Valor liberado"
        value={formatters.number.toCurrency(maxLoanValue)}
      />

      <ModalItem
        item="Taxa de juros"
        value={`${formatters.number.toPercent(
          interestRateMonthly,
          2
        )} ao mês <br />
        ${formatters.number.toPercent(interestRateYearly, 2)} ao ano`}
      />

      <ModalItem
        item="IOF máximo (Imposto sobre operação financeira)"
        value={formatters.number.toCurrency(iofValue)}
      />

      <ModalItem
        item="CET <br /> (custo efetivo total)"
        value={`${formatters.number.toPercent(cetMonthly, 2)} ao mês <br />
        ${formatters.number.toPercent(cetYearly, 2)} ao ano`}
      />

      <ModalItem
        item="Valor total a ser pago"
        value={formatters.number.toCurrency(loanTotal)}
      />

      <p className="consigNcor-modal-offer__disclaimer">
        *Proposta sujeita à análise de crédito
      </p>
    </div>
  )
}

export default ConsigNCorModalContent
